/**
 * hanging-img styling
 *
 * The styling for the images hanging down.
 * Top: Block section
 * Bottom: Three images hanging down
 */

section#hanging-img{
  .hanging-img{
    /**
     * .hanging-img__bg
     *
     * Styling for the hanging image background
     */
    &__bg{
    }

    /**
     * .hanging-img__content
     *
     * Styling for .hanging-img__content content
     */
    &__content{
      display: flex;
      flex-direction: column;
      img{
        width: 100%;
        height: auto;
      }
      /**
       * .hanging-img__content--top
       *
       * Top area stylings
       */
      &--top{
        z-index: 50;
        .box-area{
          display: flex;
          flex-direction: column;

          /**
           * .hanging-img__content--top--left
           *
           * Styling for content located at the top but to the left
           */
          &--left{
            display: flex;
            flex-direction: column-reverse;
            .small-box-area{
              display: none;
            }
          }

          /**
           * .hanging-img__content--top--right
           *
           * Styling for content located at the top but to the right
           */
          &--right{
            display: flex;
            flex-direction: column;
            .small-box-area{
              display: none;
            }
          }

          /**
           * .hanging-img__content--top--stack
           *
           */
          &--stack{
            flex-direction: column;
          }

          /**
           * .hanging-img__content--top__images
           *
           */
          &__images{
            display: flex;
            flex-direction: column;
          }
        }
        .box{
          margin-bottom: 4px;
        }

        .small-box{
          height: 82px;
          width: 82px;
          &.box{
            margin-bottom: 4px;
          }
        }
        .smaller-box{
          height: 72px;
          width: 72px;
          &.box{
            margin-top: 4px;
          }
        }
        .text-area{
          //padding: 24px 42px;
          padding: 24px 10% 24px 10%;
          min-height: 150px;
          width: 100%;
          p{
            font-size: 1.125em; /* 18/16 */
            line-height: 28px;
          }
        }
      }

      /**
       * .hanging-img__content--bottom
       *
       * Bottoms area stylings
       */
      &--bottom{
        .image-area{
          .image{
            margin: 0 auto;
            max-width: 80%;
            img{
              width: 100%;
              height: auto;
            }
            /**
             * image--1
             *
             * First hanging image
             */
            &--1{
              position: relative;
              z-index: 20;
              margin-top: -300px;
            }

            /**
             * image--2
             *
             * Second hanging image
             */
            &--2{
              position: relative;
              z-index: 10;
              margin-top: -50px;
            }

            /**
             * image--3
             *
             * Third hanging image
             */
            &--3{
              position: relative;
              z-index: 0;
              margin-top: -150px;
            }
          }
        }
      }
    }
  }
}

@media(min-width: $md){
  section#hanging-img{
    .hanging-img{
    /**
     * .hanging-img__bg
     *
     * Styling for the hanging image background
     */
      &__bg{
        padding-left: 24px;
        padding-right: 24px;
      }

    /**
     * .hanging-img__content
     *
     * Styling for .hanging-img__content content
     */
      &__content{
        display: flex;
        flex-direction: column;

      /**
       * .hanging-img__content--top
       *
       * Top area stylings
       */
        &--top{
          z-index: 10;
          .box-area{
            display: flex;
            flex-direction: row;

          /**
           * .hanging-img__content--top--left
           *
           * Styling for content located at the top but to the left
           */
            &--left{
              display: flex;
              flex-direction: row;
              margin-top: 58px;
              .small-box-area{
                display: flex;
                justify-content: flex-end;
              }
            }

          /**
           * .hanging-img__content--top--right
           *
           * Styling for content located at the top but to the right
           */
            &--right{
              display: flex;
              flex-direction: column;
              top: 46px;
              .small-box-area{
                display: flex;
                justify-content: flex-start;
              }
            }

          /**
           * .hanging-img__content--top--stack
           *
           */
            &--stack{
              flex-direction: column;
            }

          /**
           * .hanging-img__content--top__images
           *
           */
            &__images{
              display: flex;
              flex-direction: row;
            }
          }
          .box{
            margin: 4px;
          }

          .small-box{
            height: 82px;
            width: 82px;
            &.box{
              margin-bottom: 4px;
            }
          }
          .smaller-box{
            height: 72px;
            width: 72px;
            &.box{
              margin-top: 4px;
            }
          }
          .text-area{
            padding: 18px 32px;
            height: 230px;
            width: 260px;
            p{
              font-size: 1.125em; /* 18/16 */
              line-height: 28px;
            }
          }
        }

      /**
       * .hanging-img__content--bottom
       *
       * Bottoms area stylings
       */
        &--bottom{
          //position: relative;
          .image-area{
            display: flex;
            flex-direction: row;
            .image{
              flex: 1;
              justify-content: center;
              max-width: 70%;
              margin: 0 auto;
              img{
                width: 100%;
              }

            /**
             * image--1
             *
             * First hanging image
             */
              &--1{
                z-index: 0;
                margin-top: -228px;
              }

            /**
             * image--2
             *
             * Second hanging image
             */
              &--2{
                z-index: 0;
                margin-top: -72px;
              }

            /**
             * image--3
             *
             * Third hanging image
             */
              &--3{
                z-index: 0;
                margin-top: -160px;
              }
            }
          }
        }
      }
    }
  }
}

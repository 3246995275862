//==================== Gradients ====================//
$angle: 40deg;
$right: to right;

//== Orange to Dark Orange ==//
$grad-right-orange-darkOrange: linear-gradient($right, $orange, $dark-orange);
$grad-angle-orange-darkOrange: linear-gradient($angle, $orange, $dark-orange);

.grad-right-orange-darkOrange{
    background: $grad-right-orange-darkOrange;
}
.grad-angle-orange-darkOrange{
    background: $grad-angle-orange-darkOrange;
}

//== Blue to Purple ==//
$grad-right-blue-purple: linear-gradient($right, $blue, $purple);
$grad-angle-blue-purple: linear-gradient($angle, $blue, $purple);

.grad-right-blue-purple{
    background: $grad-right-blue-purple;
}
.grad-angle-blue-purple{
    background: $grad-angle-blue-purple;
}

//== Dark Green to Green ==//
$grad-right-darkGreen-green: linear-gradient($right, $green, $light-green);
$grad-angle-darkGreen-green: linear-gradient($angle, $green, $light-green);

.grad-right-darkGreen-green{
    background: $grad-right-darkGreen-green;
}
.grad-angle-darkGreen-green{
    background: $grad-angle-darkGreen-green;
}

//==================== Social Media ====================//

.social-media {
  justify-content: center;
  margin-top: $large-gutter * 2;
  //== Icons ==//
  a {
    margin-right: 37px;
    //== Last Icon ==//
    &:last-child {
      margin-right: 0px;
    }
    i {
      color: $dark-orange;
      font-size: 2.7em;
      transition: $transition;
      will-change: transform;
      //== Icon State: Hover ==//
      &:hover {
        transform: scale(1.15);
      }
    }
  }
  &__content{
    &--right{
      text-align: center;
    }
  }
}

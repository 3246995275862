/**
 *  Name tag styling
 */
.name-tag{
  padding: 16px 48px 16px 24px;
  height: auto;
  background: #000;
  opacity: 0.72;
  .name{
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  .title{
    font-size: 14px;
    font-weight: 300;
  }
  &--bottom{
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  &--right{
    position: absolute;
    right: 0;
    bottom: 42px;
    min-width: 230px;
  }
}

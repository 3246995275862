/**
 * Social media sections (in case studies) style
 */

section#social-media{
  .social-media{

    /**
     * .social-media__bg
     */
    &__bg{
      padding-top: 48px;
      padding-bottom: 48px;
    }

    /**
     * .social-media__content
     */
    &__content{
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 0 auto;
      justify-content: center;
      align-items: center;

      /**
       * .social-media__content--left
       */
      &--left{
      }

      /**
       * .social-media__content--right
       */
      &--right{

      }
      .icon-area{
        display: flex;
        flex-direction: row;
        a{
          //color: $color-grey3;
          font-size: 32px;
          margin-right: 16px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
      .text-area{
        padding: 12px 32px 24px 32px;
        padding: 12px 10% 24px 10%;
        p{
          font-size: 1.125em; /* 18/16 */
          line-height: 28px;
          font-weight: 300;
          //color: $color-grey3;
        }
      }
    }
  }
}

@media(min-width: $md){
  section#social-media{
    .social-media{

    /**
     * .social-media__bg
     */
      &__bg{
        padding-top: 48px;
        padding-bottom: 48px;
      }

    /**
     * .social-media__content
     */
      &__content{
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: 0 auto;
        justify-content: center;
        align-items: center;

        /**
         * .social-media__content--left
         */
        &--left{
        }

        /**
         * .social-media__content--right
         */
        &--right{

        }
        .icon-area{
          display: flex;
          flex-direction: row;
          a{
            //color: $color-grey3;
            font-size: 32px;
            margin-right: 16px;
            &:last-child{
              margin-right: 0;
            }
          }
        }
        .text-area{
          padding: 12px 32px 24px 32px;
          p{
            font-size: 1.125em; /* 18/16 */
            line-height: 28px;
            font-weight: 300;
            //color: $color-grey3;
          }
        }
      }
    }
  }
}

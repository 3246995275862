//==================== Import Fonts ====================//
$orange:#FB851F;
$dark-orange:#F56B21;
$green:#40BE79;
$light-green:#64D183;
$blue:#0FA9F1;
$light-blue:#57C4DD;
$red:#FB4848;
$light-red:#FB6B6B;
$purple:#7A75EE;
$light-purple:#878DF1;
$dark-grey:#474747;
$grey1:#BCBEC0;
$grey2:grey;
$grey3:#58595B;
$white: #fff;

/**
 * Hero image one block
 */

 section#hero-img--one-block{
  .hero-img--one-block{
    &__bg{
      padding-bottom: 150px;
    }
    &__content{
      .image-area{
        z-index: 20;
        img{
          width: 100%;
          height: auto;
        }
      }
      .text-area{
        //padding: 100px 24px 24px 42px;
        padding: 100px 10% 24px 10%;
        margin-top: -100px;
      }
    }
  }
}

@media(min-width: $md){
  section#hero-img--one-block{
    .hero-img--one-block{
      &__bg{
        padding-bottom: 150px;
      }
      &__content{
        .image-area{
          z-index: 20;
          img{
            width: 100%;
            height: auto;
          }
        }
        .text-area{
          padding: 24px 42px 164px 42px;
          width: 300px;
          margin-top: -200px;
        }
      }
    }
  }
}

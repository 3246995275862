/**
 * Side by side images style
 */

 section#sidebyside-img{
  .sidebyside-img{

    /**
     * .sidebyside-img__bg
     */
    &__bg{
      padding-bottom: 100px;
    }

    /**
     * .sidebyside-img__content
     */
    &__content{
      display: flex;
      flex-direction: column;
      //height: 700px;
      .text-area{
        p{
          font-size: 1.125em; /* 18/16 */
          line-height: 28px;
        }
      }
      .image-area{
        margin: 0 auto;
        max-width: 80%;
      }

    /**
     * .sidebyside-img__content--left
     */
      &--left{
        display: flex;
        flex: 1;
        flex-direction: column;
        position: relative;
        .image-area{
          z-index: 10;
          img{
            width: 100%;
            height: auto;
          }
        }
        .text-area{
          display: flex;
          flex-direction: column;
          min-height: 150px;
          //padding: 100px 24px 42px 24px;
          padding: 100px 10% 42px 10%;
          margin-top: -92px;
          width: 100%;
        }
      }

    /**
     * .sidebyside-img__content--right
     */
      &--right{
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-top: 92px;
        .image-area{
          z-index: 10;
          .image{
            &--1,
            &--2{
              img{
                width: 100%;
                height: auto;
              }
            }
          }
        }
        .text-area{
          min-height: 150px;
          width: 100%;
          //padding: 100px 24px 24px 24px;
          padding: 100px 10% 42px 10%;
          margin-top: -92px;
        }
      }
    }
  }
}

@media(min-width: $md){
  section#sidebyside-img{
    .sidebyside-img{

    /**
     * .sidebyside-img__bg
     */
      &__bg{
        padding-bottom: 125px;
      }

    /**
     * .sidebyside-img__content
     */
      &__content{
        display: flex;
        flex-direction: row;
        //height: 700px;
        .text-area{
          p{
            font-size: 1.125em; /* 18/16 */
            line-height: 28px;
          }
        }

      /**
       * .sidebyside-img__content--left
       */
        &--left{
          display: flex;
          flex: 1;
          flex-direction: column;
          //align-items: flex-end;
          position: relative;
          .image-area{
            max-width: none;
            img{
              width: 524px;
            }
          }
          .text-area{
            display: flex;
            flex-direction: column;
            min-height: 200px;
            text-align: right;
            padding: 24px 42px;
            margin-top: auto;
            width: 375px;
            p:first-child{
              margin-top: auto;
            }
            position: absolute;
            bottom: -35px;
            right: -74px;
          }
        }

      /**
       * .sidebyside-img__content--right
       */
        &--right{
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: flex-start;
          position: relative;
          padding-top: 32px;
          .image-area{
            width: 100%;
            max-width: none;
            .image{

            /**
             * .image--1
             */
              &--1{
                img{
                  width: 272px;
                }
              }

            /**
             * .image--2
             */
              &--2{
                margin-top: -16px;
                padding-right: 16px;
                display: flex;
                justify-content: flex-end;
                img{
                  width: 272px;
                }
              }
            }
          }
          .text-area{
            position: absolute;
            min-height: 400px;
            width: 350px;
            padding: 24px 62px 24px 92px;
            margin-top: auto;
            top: 112px;
            right: 0;
          }
        }
      }
    }
  }
}

#alumni-relations{
  .alternating-img{
    .alternating-img{

      /**
       * .alternating-img__bg
       *
       * The background block styling for alternating-img
       */
      &__bg{
      }

      /**
       * .alternating-img__content
       *
       * Content block styling for alternating-img
       */
      &__content{
        &--left-text{
          .text-area{
          }
          .image-area{
            margin-top: -372px;
          }
        }
        &--right-text{
          .image-area{
            img{

            }
          }
        }
      }
    }
  }
  .alternating-img-reverse{
    @extend .alternating-img;
    .alternating-img{

      /**
       * .alternating-img__bg
       *
       * The background block styling for alternating-img
       */
      &__bg{
        padding-top: 350px;
      }

      /**
       * .alternating-img__content
       *
       * Content block styling for alternating-img
       */
      &__content{
        flex-direction: column-reverse;
        &--left-text{
          .text-area{
            margin-bottom: auto;
          }
          .image-area{
            margin-top: 0;
          }
        }
        &--right-text{
          .image-area{
            margin-top: -372px;
            img{

            }
          }
        }
      }
    }
    @media(min-width: $md){
      .alternating-img{

        /**
         * .alternating-img__bg
         *
         * The background block styling for alternating-img
         */
        &__bg{

        }

        /**
         * .alternating-img__content
         *
         * Content block styling for alternating-img
         */
        &__content{
          flex-direction: column;
        }
      }
    }
  }

  section#text-image-box--large{
    .text-image-box--large{
      &__bg{
      }
      &__content{
        .image-area{
          height: 364px;
        }
      }
    }
  }
  @media(min-width: $md){
    section#text-image-box--large{
      .text-image-box--large{
        &__bg{
          padding-top: 175px;
          padding-bottom: 50px;
        }
        &__content{
          .image-area{
            height: 615px;
          }
        }
      }
    }
  }
  section#text-image-box--large-reverse{
    .text-image-box--large-reverse{
      &__bg{
      }
      &__content{
        // .image-area{
        //   height: 348px;
        // }
      }
    }
  }

  @media(min-width: $md){
    section#text-image-box--large-reverse{
      .text-image-box--large-reverse{
        &__bg{
          padding-top: 50px;
          padding-bottom: 50px;
        }
        &__content{
          // .image-area{
          //   height: 650px;
          // }
        }
      }
    }
  }
  .box{
    margin: 4px;
    &.box{
      &--sm{
        height: 64px;
        width: 64px;
      }
      &--med{
        height: 92px;
        width: 92px;
      }
      &--lg{
        height: 120px;
        width: 120px;
      }
      &--xl{
        height: 150px;
        width: 150px;
      }
    }
  }
  .col-change{
    flex-direction: column;
  }
  .box-margin-change{
    margin-bottom: 179px;
  }
}

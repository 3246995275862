/**
 * Our teamn styling
 */

 #our-team{
    .first-row{
        flex-direction: column-reverse;
        @media (min-width: $xs){
            flex-direction: row;
        }
    }
 }

/**
 * Images alternating styling
 *
 * Images alternate left and right
 */

 section#alternating-img{
  .alternating-img{

    /**
     * .alternating-img__bg
     *
     * The background block styling for alternating-img
     */
    &__bg{
      padding-top: 150px;
      padding-bottom: 150px;
      width: 100%;
    }

    /**
     * .alternating-img__content
     *
     * Content block styling for alternating-img
     */
    &__content{
      display: flex;
      flex-direction: column;
      .image-area{
        max-width: 80%;
        margin: 0 auto;
        z-index: 10;
      }
      .text-area{
        min-height: 150px;
        height: auto;
        //padding: 100px 24px 42px 24px;
        padding: 100px 10% 42px 10%;
        margin-top: -82px;
        margin-bottom: 32px;
      }

      /**
       * .alternating-img__content--left-text
       *
       * Styling for when the text block is positioned to the left of the image
       */
      &--left-text{
        display: flex;
        flex-direction: column-reverse;
        flex: 1;
        position: relative;
        .text-area{
          width: 100%;
        }
        .image-area{
          position: relative;
          img{
            width: 100%;
            height: auto;
            z-index: 10;
          }
        }
      }

      /**
       * .alternating-img__content--right-text
       *
       * Styling for when the text block is located to the right of the image
       */
      &--right-text{
        display: flex;
        flex-direction: column;
        .image-area{
          position: relative;
          img{
            width: 100%;
            height: auto;
            z-index: 10;
          }
        }
      }
    }
  }
  .top-margin-fix{
    margin-top: 400px;
  }
}

@media(min-width: $md){
  section#alternating-img{
    .alternating-img{

      /**
       * .alternating-img__bg
       *
       * The background block styling for alternating-img
       */
      &__bg{
        padding-bottom: 125px;
      }

      /**
       * .alternating-img__content
       *
       * Content block styling for alternating-img
       */
      &__content{
        display: flex;
        flex-direction: column;
        .text-area{
          height: 250px;
          max-width: 275px;
          padding: 18px 24px;
          margin-top: auto;
        }
        .image-area{
          display: flex;
          max-width: 100%;
          flex: 1;
        }

        /**
         * .alternating-img__content--left-text
         *
         * Styling for when the text block is positioned to the left of the image
         */
        &--left-text{
          display: flex;
          flex-direction: row;

          flex: 1;
          position: relative;
          .left-side{
            display: flex;
            flex: 1;
            justify-content: flex-end;
            align-items: flex-end;
          }
          .right-side{
            display: flex;
            flex: 1;
          }
          .text-area{
            margin: 4px 4px 4px 0;
            text-align: right;
            padding-left: 48px;
          }
          .image-area{
            margin: 4px -2px 4px 4px;
            margin-top: -305px;
          }
        }

        /**
         * .alternating-img__content--right-text
         *
         * Styling for when the text block is located to the right of the image
         */
        &--right-text{
          display: flex;
          flex-direction: row;
          flex: 1;
          position: relative;
          .left-side{
            display: flex;
            flex: 1;
            justify-content: flex-end;
            align-items: flex-end;
          }
          .right-side{
            display: flex;
            flex: 1;
          }
          .text-area{
            margin: 4px 0 4px 4px;
            text-align: left;
            padding-right: 72px;
          }
          .image-area{
            margin: 4px 4px 4px -4px;
          }
        }
      }
    }
    .top-margin-fix{
      margin-top: 0;
    }
  }
}

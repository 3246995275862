#jordane{
  @media(min-width: $md){
    section#hero-img--large{
      .hero-img--large{

        /**
         * .hero-img--large__bg
         */
        &__bg{
            min-height: 1100px;
        }

        /**
         * .hero-img--large__content
         */
        &__content{

        /**
         * .hero-img--large__content--left
         */
          &--left{
            margin-top: 190px;
          }

        /**
         * .hero-img--large__content--right
         */
          &--right{
          }
          .text-area{
            min-height: 225px;
          }
          .image-area-2{
            position: absolute;
            img{
            }
          }
        }
      }
    }
  }
}



//==================== Defaults ====================//

html,
body{
  font-family: $primaryFont;
  font-weight: $fontNormal;
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  animation: fadein 0.42s ease-out;
  cursor: default;
}

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

p{
  margin: 0;
}

.container:after {
  clear: both;
  content: "";
  display: block;
}

//==================== Fonts ====================//

//== Import Montserrat ==//
@font-face {
  font-family: Montserrat;
  src: local("montserrat-hairline-webfont"), url("../fonts/montserrat-hairline-webfont.woff") format("woff");
  font-weight: 200;
}

@font-face {
  font-family: Montserrat;
  src: local("montserrat-light-webfont"), url("../fonts/montserrat-light-webfont.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: Montserrat;
  src: local("montserrat-regular-webfont"), url("../fonts/montserrat-regular-webfont.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: Montserrat;
  src: local("montserrat-bold-webfont"), url("../fonts/montserrat-bold-webfont.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: Montserrat;
  src: local("montserrat-black-webfont"), url("../fonts/montserrat-black-webfont.woff") format("woff");
  font-weight: 900;
}

//== Primary Font ==//
$primaryFont: Montserrat, sans-serif;

//== Font Weights ==//
$fontHairline: 200;
$fontLight: 300;
$fontNormal: 400;
$fontBold: 700;
$fontBlack: 900;

/**
 * Typography styles
 */

  .xl-text{
    font-size: 1.75em; /* 32/16 */
  }
  .xl-text-2{
    font-size: 2em; /* 32/16 */
  }
  .xxl-text{
    font-size: 1.75em; /* 28/16 */
  }
  .lg-text{
    font-size: 1.5em; /* 24/16 */
  }
  .med-text{
    font-size: 1.125em; /* 20/16 */
    line-height: 26px;
  }
  .w-300{
    font-weight: 300;
  }
  .w-600{
    font-weight: 600;
  }

/**
 * Tablet
 */
@media (min-width: $xxs){
      .xl-text{
        font-size: 2.0em; /* 32/16 */
      }
      .xxl-text{
        font-size: 36px;
      }
      .lg-text{
        font-size: 1.5em; /* 24/16 */
      }
      .med-text{
        font-size: 1.125em;
        line-height: 42px;
      }
      .w-300{
        font-weight: 300;
      }
      .w-600{
        font-weight: 600;
      }
}
/**
 * Desktop
 */
@media (min-width: $xs){
  .xxl-text{
    font-size: 2.75em; /* 44/16 */
  }
  .xl-text{
    font-size: 2.5em; /* 32/16 */
  }
  .lg-text{
    font-size: 1.5em; /* 24/16 */
  }
  .med-text{
    font-size: 1.125em; /* 18/16 */
    line-height: 32px;
  }
  .sm-text{
    font-size: 1em;
    line-height: 32px;
  }
  .w-300{
    font-weight: 300;
  }
  .w-600{
    font-weight: 600;
  }
}

a{
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
  &:visited{
    text-decoration: none;
  }
}

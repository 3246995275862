/**
 * image-large--three-image-stack style
 */

section#image-large--three-image-stack{
    .image-large--three-image-stack{

        /**
         * .image-large--three-image-stack__bg
         */
        &__bg{
            padding-top: 150px;
            padding-bottom: 150px;
        }

        /**
         * .image-large--three-image-stack__content
         */
        &__content{
            display: flex;
            flex-direction: column;
            position: relative;
            .top-area{
                position: relative;
                .large-image{
                    padding-left: 10%;
                    padding-right: 10%;
                    img{
                        max-width: 100%;
                        height: auto;
                    }
                }
                .image-area{
                    z-index: 10;
                    padding-left: 10%;
                    padding-right: 10%;
                    margin-top: -24px;
                    img{
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
            .bottom-area{
                .text-area{
                    //padding: 150px 24px 42px 24px;
                    padding: 150px 10% 42px 10%;
                    margin-top: -75px;
                    p{
                      font-size: 1.125em; /* 18/16 */
                      line-height: 28px;
                    }
                }
            }
        }
    }
}

@media(min-width: $md){
    section#image-large--three-image-stack{
        .image-large--three-image-stack{

          /**
           * .image-large--three-image-stack__bg
           */
            &__bg{

            }

          /**
           * .image-large--three-image-stack__content
           */
            &__content{
                display: flex;
                flex-direction: column-reverse;
                position: relative;
                .top-area{
                    display: flex;
                    flex-direction: row;
                    .large-image{
                        margin-right: -30px;
                        margin-left: -30px;
                        z-index: 10;
                        padding-left: 0;
                        padding-right: 0;
                    }
                    .image-area{
                        margin: auto 0;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
                .bottom-area{
                    .text-area{
                        width: 400px;
                        max-width: 400px;
                        min-height: 400px;
                        padding: 24px 42px 250px 42px;
                        margin-bottom: -200px;
                    }
                }
            }
        }
    }
}

/**
 * Hero image large style
 */

 section#hero-img--large{
  .hero-img--large{

    /**
     * .hero-img--large__bg
     */
    &__bg{
      padding-bottom: 150px;
    }

    /**
     * .hero-img--large__content
     */
    &__content{
      display: flex;
      flex-direction: column-reverse;

    /**
     * .hero-img--large__content--left
     */
      &--left{
        flex: 1;
        position: relative;
      }

    /**
     * .hero-img--large__content--right
     */
      &--right{
        flex: 1;
        position: relative;
      }
      .text-area{
        //padding: 102px 24px 42px 24px;
        padding: 102px 10% 42px 10%;
        margin-top: -102px;
        p{
          font-size: 1.125em; /* 18/16 */
          line-height: 28px;
        }
      }
      .image-area{
        padding-bottom: 32px;
        max-width: 80%;
        margin: 0 auto;
        img{
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media(min-width: $md){
  section#hero-img--large{
    .hero-img--large{

    /**
     * .hero-img--large__bg
     */
      &__bg{
        min-height: 925px;
      }

    /**
     * .hero-img--large__content
     */
      &__content{
        display: flex;
        flex-direction: row;

      /**
       * .hero-img--large__content--left
       */
        &--left{
          flex: 1;
          position: relative;
        }

      /**
       * .hero-img--large__content--right
       */
        &--right{
          flex: 1;
          position: relative;
        }
        .text-area{
          //background: $grad-right-blue-purple;
          padding: 24px 42px;
          margin-top: auto;
          p{
            font-size: 1.125em; /* 18/16 */
            line-height: 28px;
            max-width: 50%;
          }
        }
        .image-area{
          position: absolute;
          max-width: 100%;
          left: -325px;
          top: -75px;
          img{
            width: auto;
          }
        }
      }
    }
  }
}

section#headline{
  .headline{

    /**
     * .headline__bg
     */
    &__bg{
      position: relative;
      padding-bottom: 150px;
    }

    /**
     * .headline__content
     */
    &__content{
      display: flex;
      flex-direction: column;
      .image-area{
        position: relative;
        margin: 0 auto;
        img{
          width: 100%;
          max-width: 450px;
          height: auto;
        }
      }

    /**
     * .headline__content--right
     */
      &--right{
        display: flex;
        flex-direction: column;
      }
      .text-area{
        flex: 1;
        padding-left: 20px;
        padding-right: 20px;
        &.top{
          padding-left: 0px;
          padding-right: 0px;
        }
      }
      .title-area{
        display: flex;
        flex-direction: row;
        background: $grad-right-orange-darkOrange;
        padding: 200px 24px 24px 24px;
        margin-top: -182px;
        .client-name{
          margin-bottom: -28px;
        }
      }
      .blurb-area{
        display: flex;
        padding-top: 32px;
        padding-bottom: 32px;
        p{
          margin-bottom: 16px;
          font-size: 1em;
          line-height: 28px;
        }
      }
      .project-name{
        margin-bottom: 75px;
      }
      .project-type{
        .project-type__item{
          display: inline-block;
          border: #fff 1px solid;
          border-radius: 3px;
          background-color: transparent;
          padding: 2px 12px;
          font-size: 12px;
          margin-right: 12px;
          margin-bottom: 12px;
        }
      }
    }
  }
}

@media(min-width: $md){
  section#headline{
    .headline{

    /**
     * .headline__bg
     */
      &__bg{
        position: relative;
      }

    /**
     * .headline__content
     */
      &__content{
        display: flex;
        flex-direction: row;
        .image-area{
          margin: 0 auto;
          position: absolute;
          top: -32px;
          left: 15%;
          transform: translateX(-15%);
        }

      /**
       * .headline__content--right
       */
        &--right{
          display: flex;
          flex-direction: column;
        }
        .text-area{
          flex: 1;
        }
        .title-area{
          display: flex;
          flex-direction: row;
          background: $grad-right-orange-darkOrange;
          padding-top: 32px;
          padding-bottom: 32px;
          margin-top: auto;
          .client-name{
            margin-bottom: -28px;
          }
        }
        .blurb-area{
          display: flex;
          padding-top: 32px;
          padding-bottom: 32px;
          p{
            margin-bottom: 16px;
            font-size: 1em;
            line-height: 28px;
          }
        }
        .project-type{
          .project-type__item{
            display: inline-block;
          }
        }
      }
    }
  }
}

//==================== Container ====================//

//== Basic ==//
.container {
  margin: 0 auto;
  padding-left: $gutter;
  padding-right: $gutter;
  &--regular{
    max-width: 1140px;
  }
  /*
  clear: both;
  &:after {
    clear: both;
    content: "";
    display: block;
  }
  */
}

//== Full Width Container ==//
/*
.full-width {
  width: 100%;
  padding-left: $gutter;
  padding-right: $gutter;
}
*/

/**
 * block-area styles
 */

section#block-area{
  /**
   * #block-area-1
   *
   * Styling for a variety of block-area (#1)
   */
  &-1{

  }

  /**
   * #block-area-2
   *
   * Styling for a variety of block-area (#2)
   */
  &-2{
    .block-area-2{
      /**
       * .block-area-2__bg
       */
      &__bg{

      }
      /**
       * .block-area-2__content
       */
      &__content{
        .text-area{
          padding: 42px 24px;
          p{
            font-size: 1.125em; /* 18/16 */
            line-height: 28px;
          }
        }
        .row{
          /**
           * .row-1
           */
          &-1{
            display: flex;
            flex-direction: column;
            .row{
              &:nth-child(2){
                display: flex;
                flex-direction: row;
                .row{
                  display: flex;
                  flex-direction: column;
                  flex: 1;
                  .block{
                      height: 200px;
                  }
                }
              }
            }
          }
          /**
           * .row-2
           */
          &-2{
            margin-top: 48px;
            flex-direction: column;
            .block--lg{
                height: 410px;
            }
          }
          /**
           * .row-3
           */
          &-3{
            margin-top: 48px;
            .block{
              height: 175px;
              min-height: 175px;
            }
          }
        }
      }
    }
  }
}

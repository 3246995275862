/**
 * hero-image--two-blocks style
 */

section#hero-image--two-blocks{
    .hero-image--two-blocks{

        /**
         * .hero-image--two-blocks__bg
         */
        &__bg{
            padding-top: 150px;
            padding-bottom: 150px;
        }

        /**
         * .hero-image--two-blocks__content
         */
        &__content{
            display: flex;
            flex-direction: column-reverse;
            .image-area{
                z-index: 10;
                padding-left: 10%;
                padding-right: 10%;
                img{
                    max-width: 100%;
                    height: auto;
                }
            }
            .text-area{
                //padding: 124px 24px 42px 24px;
                padding: 124px 10% 42px 10%;
                margin-top: -100px;
                p{
                  font-size: 1.125em; /* 18/16 */
                  line-height: 28px;
                }
            }
            .left-side{

            }
            .right-side{

            }
        }
    }
}

@media(min-width: $md){
    section#hero-image--two-blocks{
        .hero-image--two-blocks{

          /**
           * .hero-image--two-blocks__bg
           */
            &__bg{
                height: 1050px;
            }

          /**
           * .hero-image--two-blocks__content
           */
            &__content{
                flex-direction: row;
                display: flex;
                height: 100%;
                position: relative;
                .left-side{
                    align-items: flex-end;
                    .text-area{
                        padding: 24px 42px 24px 42px;
                        margin-top: auto;
                        width: 350px;
                        max-width: 350px;
                        min-height: 300px;
                        justify-content: flex-start;
                        align-items: flex-start;
                    }
                }
                .right-side{
                    flex: 1;
                    .image-area{
                        position: absolute;
                        padding: 0;
                        left: 150px;
                        bottom: -215px;
                    }
                    .big-block{
                        width: 100%;
                        height: 85%;
                        margin-left: 8px;
                    }
                }
            }
        }
    }
}

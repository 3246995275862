/**
 * hover-image--three style
 */

section#hover-image--three{
    .hover-image{
        /**
         * .hover-image--three
         *
         * hover image with three images
         */
        &--three{

            /**
             * hover-image--three__content
             */
            &__content{
                display: flex;
                flex-direction: column;
                .top-area{
                    //position: relative;
                    .image-area{
                        padding-right: 10%;
                        padding-left: 10%;
                        img{
                            max-width: 100%;
                            height: auto;
                        }
                    }
                    .image{
                        &-one{
                            z-index: 10;
                        }
                        &-two{
                            z-index: 10;
                        }
                        &-three{
                            z-index: 10;
                            width: 75%;
                            margin: 0 auto;
                        }
                    }
                }
                .bottom-area{
                    .text-area{
                        //padding: 42px 24px;
                        padding: 42px 10%;
                        //margin-top: -100px;
                        p{
                          font-size: 1.125em; /* 18/16 */
                          line-height: 28px;
                        }
                    }
                }
            }
        }
    }
}

@media(min-width: $md){
    section#hover-image--three{
        .hover-image{
            /**
             * .hover-image--three
             *
             * hover image with three images
             */
            &--three{

                /**
                 * .hover-image--three__bg
                 */
                &__bg{
                    padding-top: 150px;
                    //padding-bottom: 150px;
                }

                /**
                 * .hover-image--three__content
                 */
                &__content{
                    display: flex;
                    flex-direction: row-reverse;
                    position: relative;
                    height: 950px;
                    .top-area{
                        flex: 1;
                        .image-area{
                            padding-right: 0;
                            padding-left: 0;
                            .image{
                                &-one{
                                    position: absolute;
                                    left: 0;
                                    top: 200px;
                                }
                                &-two{
                                    position: absolute;
                                }
                                &-three{
                                    position: absolute;
                                    top: 500px;
                                    left: 440px;
                                    width: auto;
                                }
                            }
                        }
                    }
                    .bottom-area{
                        flex: 1;
                        justify-content: flex-start;
                        .text-area{
                            max-width: 400px;
                            min-height: 400px;
                            padding: 24px 42px;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}

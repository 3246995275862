//==================== Header ====================//

header {
  display: flex;
  height: 20vh;
  margin: auto;
  //padding-top: 82px;
  //padding-bottom: 32px;
}

@media (max-width: $xs){
  header {
      height: 30vh;
    }
}

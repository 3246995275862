/**
 * image-text--top style
 */
section#image-text--top{
    .image-text--top{

        /**
         * .image-text--top__content
         */
        &__content{
            display: flex;
            flex-direction: column;
            padding-bottom: 150px;
            .text-area{
                //padding: 150px 24px 42px 24px;
                padding: 150px 10% 42px 10%;
                margin-top: -115px;
            }
            .top-area{
                z-index: 10;
            }
            .image-area{
                padding-left: 10%;
                padding-right: 10%;
                img{
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
}

@media(min-width: $md){
    section#image-text--top{
        .image-text--top{

            /**
             * .image-text--top__content
             */
            &__content{
                flex-direction: column-reverse;
                padding-top: 150px;
                .bottom-area{
                    margin: 0 auto;
                    .text-area{
                        padding: 48px;
                        line-height: 28px;
                        font-size: 1.125em; /* 18/16 */
                        min-height: 325px;
                        z-index: -10;
                        max-width: 600px;
                    }
                }
                .top-area,
                .bottom-area{
                    position: relative;
                }
                .image-area{
                    position: relative;
                    height: 1200px;
                    padding-left: 0;
                    padding-right: 0;
                    img{
                        position: absolute;
                        top: -100px;
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}

//==================== Footer ====================//

footer {
  margin-top: 75px;
  margin-bottom: 48px;
  svg {
    width: 162px;
    margin-bottom: $large-gutter;
  }
  p {
    line-height: 1.5;
    //== Copy Write ==//
    &.copyright {
      width: 100%;
      text-align: center;
      font-size: 12px;
      margin-top: 34px;
    }
  }
}

@media (min-width: $xxs){
  footer{
    margin-top: 100px;
  }
}

/*
 * Util styles
 * Helper, utility classes
 */

 .sm{
    &--hide{
        display: none;
    }
 }
 .lg{
    &--hide{
        display: block;
    }
 }
 .spacer{
    flex: 1;
 }

 .col-reverse{
    flex-direction: column-reverse;
 }

 .row-reverse{
    flex-direction: row-reverse;
 }

 .push{
    &--left{
        margin-right: auto;
    }
    &--right{
        margin-left: auto;
    }
    &--bottom{
        margin-top: auto;
    }
    &--top{
        margin-bottom: auto;
    }
    &--center-vert{
        margin: 0 auto;
    }
    &--center-horz{
        margin: auto 0;
    }
    &--center{
        margin: auto;
    }
 }

 .vert-spacer{
    &--16{
        height: 16px;
    }
    &--24{
        height: 24px;
    }
    &--32{
        height: 32px;
    }
    &--42{
        height: 42px;
    }
    &--64{
        height: 64px;
    }
 }

 .list--basic{
    padding-left: 0;
    list-style: none;
 }

@media (min-width: $md){
  .sm{
    &--hide{
        display: block;
    }
  }
  .lg{
    &--hide{
        display: none;
    }
  }
}

/**
 * Hero image large style
 */

 section#hero-img--single{
  .hero-img--single{
    &__bg{
      padding-bottom: 150px;
    }
    &__content{
      img{
        width: 100%;
        height: auto;
      }
    }
  }
}

/**
* A full width block that has three images
* One above the box
* Two below the box left and right
*
* #three-image__box--fullwidth
*
* Images alternate left and right
*/

section#three-image__box--fullwidth{
  .three-image__box--fullwidth{
    &__bg{
      position: relative;
      margin-bottom: 150px;
    }
    &__content{
      .block-area{
        width: 100%;
        height: 250px;
      }
      .image-area{
        img{
          width: 100%;
          height: auto;
        }
        .bottom-area{
          display: flex;
          flex-direction: row;
        }
        .image{
          &--one{

          }
          &--two{

          }
          &--three{

          }
        }
      }
    }
  }
}

@media(min-width: $md){
  section#three-image__box--fullwidth{
    .three-image__box--fullwidth{
      &__bg{
        position: relative;
      }
      &__content{
        .block-area{
          margin-top: -540px;
          margin-bottom: 450px;
        }
        .image-area{
          padding: 0 100px;
          margin: 0 auto;
          z-index: 20;
          .bottom-area{
            display: flex;
            flex-direction: row;
          }
          .image{
            &--one{
              margin: 0 auto;
            }
            &--two{
              margin: 0 auto;
            }
            &--three{
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

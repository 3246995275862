section#text-image-box--large{
  .text-image-box--large{
    &__bg{
      padding-bottom: 100px;
    }
    &__content{
      .image-area{
        height: 466px;
        justify-content: center;
        img{
          padding: 42px 42px 0 42px;
        }
      }
      .block__img{
        img{
          top: 100%;
          transform: translateY(-100%);
          height: 100%;
          width: auto;
        }
      }
    }
  }
}

@media(min-width: $md){
  section#text-image-box--large{
    .text-image-box--large{
      &__bg{
        padding-top: 100px;
        padding-bottom: 100px;
      }
      &__content{
        .image-area{
          height: 800px;
          img{
            padding: 100px 100px 0 100px;
          }
        }
      }
    }
  }
}

section#headline--reverse{
  .headline--reverse{

    /**
     * .headline__bg
     */
    &__bg{
      position: relative;
      padding-bottom: 150px;
      min-height: 300px;
    }

    /**
     * .headline__content
     */
    &__content{
      display: flex;
      flex-direction: column-reverse;
      .image-area{
        position: relative;
        margin: 0 auto;
        img{
          width: 100%;
          max-width: 450px;
          height: auto;
        }
      }

    /**
     * .headline__content--right
     */
      &--right{
        display: flex;
        flex-direction: column;
      }
      .text-area{
        flex: 1;
        padding-left: 32px;
        padding-right: 32px;
      }
      .title-area{
        display: flex;
        flex-direction: row;
        background: $grad-right-orange-darkOrange;
        padding: 200px 24px 24px 24px;
        margin-top: -182px;
        .client-name{
          margin-bottom: -28px;
        }
      }
      .blurb-area{
        display: flex;
        //padding-top: 32px;
        padding-bottom: 32px;
        p{
          margin-bottom: 16px;
          font-size: 1em;
          line-height: 28px;
        }
      }
    }
  }
}

@media(min-width: $md){
  section#headline--reverse{
    .headline--reverse{

    /**
     * .headline__bg
     */
      &__bg{
        position: relative;
      }

    /**
     * .headline__content
     */
      &__content{
        display: flex;
        flex-direction: row;
        .image-area{
          margin: 0 auto;
          position: absolute;
          top: -32px;
          right: 4%;
          transform: translateX(-4%);
        }

      /**
       * .headline__content--right
       */
        &--right{
          display: flex;
          flex-direction: column;
        }
        .text-area{
          flex: 1;
        }
        .title-area{
          display: flex;
          flex-direction: row;
          background: $grad-right-orange-darkOrange;
          padding-top: 32px;
          padding-bottom: 48px;
          margin-top: auto;
          min-height: 375px;
          .client-name{
            margin-bottom: -28px;
          }
        }
        .blurb-area{
          display: flex;
          //padding-top: 32px;
          padding-bottom: 32px;
          p{
            margin-bottom: 16px;
            font-size: 1em;
            line-height: 28px;
          }
        }
      }
    }
  }
}

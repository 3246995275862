/**
 * SIO Case Study Stylings
 */
#sio{
  section#vertical-images{
    &--three{
      &__bg{

      }
      &__content{
        display: flex;
        flex-direction: column;
      }
    }
  }

  section#hero-img{
    &--text-image-1{
      .hero-img--text-image-1{
        &__bg{
          padding-bottom: 150px;
        }
        &__content{
          display: flex;
          flex-direction: column;
          img{
            max-width: 100%;
            height: auto;
          }
          .image-area{
            z-index: 10;
            margin-left: 10%;
            margin-right: 10%;
          }
          .text-area{
            padding: 150px 24px 42px 24px;
            margin-top: -125px;
            p{
              font-size: 1.125em; /* 18/16 */

              line-height: 28px;
            }
          }
        }
      }
    }
    &--large{
      .hero-img--large{
        &__bg{
          padding-bottom: 150px;
        }
        &__content{
          display: flex;
          flex-direction: column-reverse;
          position: relative
          &--left{
            flex: 1;
            position: relative;
          }
          &--right{
            flex: 1;
            position: relative;
          }
          .text-area{
            //background: $grad-right-blue-purple;
            padding: 102px 24px 42px 24px;
            margin-top: -102px;
            font-size: 1.125em; /* 18/16 */
            line-height: 28px;
          }
          .image-area{
            padding-bottom: 32px;
            max-width: 80%;
            margin: 0 auto;
            img{
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }

  @media(min-width: $md){
    section#hero-img{
      &--text-image-1{
        .hero-img--text-image-1{
          &__bg{
            padding-top: 150px;
            padding-bottom: 150px;
          }
          &__content{
            position: relative;
            .image-area{
              z-index: 0;
              margin-left: 0;
              margin-right: 0;
            }
            .text-area{
              padding: 42px 24px 102px 24px;
              position: absolute;
              z-index: 10;
              max-width: 225px;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }
  }

  section#block-images{
    .block-images{
      &__bg{

      }
      &__content{
        &--top{
          display: flex;
          &--left{
            display: flex;
            flex-direction: column;
          }
          &--right{
            display: flex;
            flex-direction: column;
          }
        }
        &--bottom{
          display: flex;
          flex-direction: column;
        }
        .justify{
          &--right{
            justify-content: flex-end;
          }
          &--left{
            justify-content: flex-start;
          }
        }
        .box{
          margin: 4px;
          &--sm{
            width: 135px;
            height: 135px;
          }
        }
      }
    }
  }

  @media(min-width: $md){
    section#block-images{
      .block-images{
        &__bg{

        }
        &__content{
          &--top{
            //display: flex;
            //flex-direction: column;
            flex-direction: row;
            &-left{
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              flex: 3;
            }
            &-right{
              display: flex;
              flex-direction: column;
              margin-top: auto;
              flex: 1;
            }
          }
          &--bottom{
            align-items: flex-start;
            flex-direction: row;
            &-left{
              flex-direction: column;
              flex: 2;
            }
            &-middle{
              display: flex;
              flex-direction: column;
              flex: 1;
            }
            &-right{
              flex: 1;
            }
          }
        }
      }
    }
  }

  section#sidebyside-img{
    .sidebyside-img{
      &__bg{
        padding-bottom: 100px;
      }
      &__content{
        display: flex;
        flex-direction: column;
        //height: 700px;
        .text-area{
          p{
            font-size: 1.125em; /* 18/16 */
            line-height: 28px;
          }
        }
        .image-area{
          margin: 0 auto;
          max-width: 80%;
        }
        &--left{
          display: flex;
          flex: 1;
          flex-direction: column;
          position: relative;
          .image-area{
            z-index: 10;
            img{
              width: 100%;
              height: auto;
            }
          }
          .text-area{
            display: flex;
            flex-direction: column;
            min-height: 150px;
            padding: 100px 24px 42px 24px;
            margin-top: -92px;
            width: 100%;
          }
        }
        &--right{
          display: flex;
          flex: 1;
          flex-direction: column;
          padding-top: 92px;
          .image-area{
            z-index: 10;
            .image{
              &--1,
              &--2{
                img{
                  width: 100%;
                  height: auto;
                }
              }
            }
          }
          .text-area{
            min-height: 150px;
            width: 100%;
            padding: 100px 24px 24px 24px;
            margin-top: -92px;
          }
        }
      }
    }
  }

  section#social-media{
    .social-media{
      &__bg{
        padding-top: 48px;
        padding-bottom: 48px;
      }
      &__content{
        display: flex;
        flex-direction: row;
        flex: 1;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        &--left{
        }
        &--right{

        }
        .icon-area{
          display: flex;
          flex-direction: row;
          a{
            //color: $color-grey3;
            font-size: 32px;
            margin-right: 16px;
            &:last-child{
              margin-right: 0;
            }
          }
        }
        .text-area{
          padding: 24px 32px;
          p{
            font-size: 1.125em; /* 18/16 */
            line-height: 28px;
            font-weight: 300;
            //color: $color-grey3;
          }
        }
      }
    }
  }

  section#hanging-img{
    .hanging-img{
      &__bg{
      }
      &__content{
        display: flex;
        flex-direction: column;
        img{
          width: 100%;
          height: auto;
        }
        &--top{
          z-index: 50;
          .box-area{
            display: flex;
            flex-direction: column;
            &--left{
              display: flex;
              flex-direction: column-reverse;
              .small-box-area{
                display: none;
              }
            }
            &--right{
              display: flex;
              flex-direction: column;
              .small-box-area{
                display: none;
              }
            }
            &--stack{
              flex-direction: column;
            }
            &__images{
              display: flex;
              flex-direction: column;
            }
          }
          .box{
            margin-bottom: 4px;
          }

          .small-box{
            height: 82px;
            width: 82px;
            &.box{
              margin-bottom: 4px;
            }
          }
          .smaller-box{
            height: 72px;
            width: 72px;
            &.box{
              margin-top: 4px;
            }
          }
          .text-area{
            padding: 24px 42px;
            min-height: 150px;
            width: 100%;
            p{
              font-size: 1.125em; /* 18/16 */
              line-height: 28px;
            }
          }
        }
        &--bottom{
          //position: relative;
          .image-area{
            .image{
              margin: 0 auto;
              max-width: 80%;
              img{
                width: 100%;
                height: auto;
              }
              &--1{
                position: relative;
                z-index: 20;
                margin-top: -300px;
              }
              &--2{
                position: relative;
                z-index: 10;
                margin-top: -50px;
              }
              &--3{
                position: relative;
                z-index: 0;
                margin-top: -150px;
              }
            }
          }
        }
      }
    }
  }

  @media(min-width: $md){
    section#hero-img--large{
      .hero-img--large{
        &__bg{
          min-height: 925px;
        }
        &__content{
          display: flex;
          flex-direction: row;
          &--left{
            flex: 1;
            position: relative;
          }
          &--right{
            flex: 1;
            position: relative;
          }
          .text-area{
            //background: $grad-right-blue-purple;
            padding: 24px 42px;
            margin-top: auto;
            p{
              font-size: 1.125em; /* 18/16 */
              line-height: 28px;
              max-width: 50%;
            }
          }
          .image-area{
            position: absolute;
            max-width: 100%;
            left: -380px;
            top: -32px;
            img{
              width: auto;
            }
          }
        }
      }
    }

    section#sidebyside-img{
      .sidebyside-img{
        &__bg{
          padding-bottom: 125px;
        }
        &__content{
          display: flex;
          flex-direction: row;
          //height: 700px;
          .text-area{
            p{
              font-size: 1.125em; /* 18/16 */
              line-height: 28px;
            }
          }
          &--left{
            display: flex;
            flex: 1;
            flex-direction: column;
            //align-items: flex-end;
            position: relative;
            .image-area{
              max-width: none;
              img{
                width: 524px;
              }
            }
            .text-area{
              display: flex;
              flex-direction: column;
              min-height: 200px;
              text-align: right;
              padding: 24px 42px;
              margin-top: auto;
              width: 375px;
              p:first-child{
                margin-top: auto;
              }
              position: absolute;
              bottom: 32px;
              right: -74px;
            }
          }
          &--right{
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            padding-top: 32px;
            .image-area{
              width: 100%;
              max-width: none;
              .image{
                &--1{
                  img{
                    width: 272px;
                  }
                }
                &--2{
                  margin-top: -16px;
                  padding-right: 16px;
                  display: flex;
                  justify-content: flex-end;
                  img{
                    width: 272px;
                  }
                }
              }
            }
            .text-area{
              position: absolute;
              min-height: 400px;
              width: 350px;
              padding: 24px 62px 24px 92px;
              margin-top: auto;
              top: 112px;
              right: 0;
            }
          }
        }
      }
    }

    section#social-media{
      .social-media{
        &__bg{
          padding-top: 48px;
          padding-bottom: 48px;
        }
        &__content{
          display: flex;
          flex-direction: row;
          flex: 1;
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          &--left{
          }
          &--right{

          }
          .icon-area{
            display: flex;
            flex-direction: row;
            a{
              //color: $color-grey3;
              font-size: 32px;
              margin-right: 16px;
              &:last-child{
                margin-right: 0;
              }
            }
          }
          .text-area{
            padding: 24px 32px;
            p{
              font-size: 1.125em; /* 18/16 */
              line-height: 28px;
              font-weight: 300;
              //color: $color-grey3;
            }
          }
        }
      }
    }

    section#hanging-img{
      .hanging-img{
        &__bg{
          padding-left: 24px;
          padding-right: 24px;
        }
        &__content{
          display: flex;
          flex-direction: column;
          &--top{
            z-index: 10;
            .box-area{
              display: flex;
              flex-direction: row;
              &--left{
                display: flex;
                flex-direction: row;
                margin-top: 58px;
                .small-box-area{
                  display: flex;
                  justify-content: flex-end;
                }
              }
              &--right{
                display: flex;
                flex-direction: column;
                top: 46px;
                .small-box-area{
                  display: flex;
                  justify-content: flex-start;
                }
              }
              &--stack{
                flex-direction: column;
              }
              &__images{
                display: flex;
                flex-direction: row;
              }
            }
            .box{
              margin: 4px;
            }

            .small-box{
              height: 82px;
              width: 82px;
              &.box{
                margin-bottom: 4px;
              }
            }
            .smaller-box{
              height: 72px;
              width: 72px;
              &.box{
                margin-top: 4px;
              }
            }
            .text-area{
              padding: 18px 32px;
              height: 230px;
              width: 260px;
              p{
                font-size: 1.125em; /* 18/16 */
                line-height: 28px;
              }
            }
          }
          &--bottom{
            //position: relative;
            .image-area{
              display: flex;
              flex-direction: row;
              .image{
                flex: 1;
                justify-content: center;
                max-width: 70%;
                margin: 0 auto;
                img{
                  width: 100%;
                }
                &--1{
                  z-index: 0;
                  margin-top: -228px;
                }
                &--2{
                  z-index: 0;
                  margin-top: -72px;
                }
                &--3{
                  z-index: 0;
                  margin-top: -160px;
                }
              }
            }
          }
        }
      }
    }
  }
}

/**
 * Siva Profile Page
 */

 #siva-profile{
    .wall-e-image{
        height: 100%;
        width: auto;
        padding: 16px;
        margin-left: 8%;
    }
    .linux-image{
        margin-top: 18%;
        max-width: 75%;
        margin-left: 16%;
    }
 }

#sobol{
  section#text-image-box--large{
    .text-image-box--large{
      &__bg{
        padding-bottom: 100px;
      }
      &__content{
        .image-area{
          height: 466px;
          img{
            padding: 42px 42px 0 42px;
          }
        }
      }
    }
  }
  @media(min-width: $md){
    section#text-image-box--large{
      .text-image-box--large{
        &__bg{
          padding-top: 150px;
          padding-bottom: 0px;
        }
        &__content{
          .image-area{
            height: 800px;
            img{
              padding: 150px 150px 0 150px;
            }
          }
        }
      }
    }
  }
  section#text-image-box--large-reverse{
    .text-image-box--large-reverse{
      &__bg{
        padding-bottom: 100px;
      }
      &__content{
        .reverse{
          flex-direction: column;
        }
        .image-area{
          height: 348px;
          img{
            padding: 42px 42px 0 42px;
          }
        }
      }
    }
  }
  .box{
    margin: 4px;
    &.box{
      &--sm{
        height: 64px;
        width: 64px;
      }
      &--med{
        height: 92px;
        width: 92px;
      }
      &--lg{
        height: 120px;
        width: 120px;
      }
      &--xl{
        height: 150px;
        width: 150px;
      }
    }
  }
  .single-block{
      margin-left: 968px;
  }
  .col-change{
    flex-direction: column;
  }
  .box-margin-change{
    margin-bottom: 364px;
  }
  @media(min-width: $md){
    section#text-image-box--large-reverse{
      .text-image-box--large-reverse{
        &__bg{
          padding-top: 0px;
          padding-bottom: 150px;
        }
        &__content{
          .reverse{
            flex-direction: row-reverse;
          }
          .image-area{
            height: 720px;
            img{
              padding: 100px 100px 0 100px;
            }
          }
        }
      }
    }
  }
}

/**
 * studio styling
 */

 #studio{
    .logo-area{
        @media (min-width: $xs){
            height: 150px;
        }
        //width: 100%;
        .img-area{
            padding: 48px 16px;
            margin: auto;
            flex-direction: column;
            @media (min-width: $xs){
                padding: 48px 16px;
                margin: 0 18px 0 0;
            }
            &.sparq-logo{
                img{
                    max-height: 42px;
                    margin-top: 8px;
                }
            }
            img{
                height: 100%;
                //width: auto;
            }
        }
    }
    .section-heading{
        margin-bottom: 24px;
    }
    .text-area{
        p{
            margin-bottom: 16px;
            @media (min-width: $xs){
                margin-bottom: 0;
            }
        }
        @media (min-width: $xs){
            .left-area{
                margin-right: 24px;
            }
            .right-area{
                margin-left: 24px;
            }
        }
    }
 }

@keyframes shake{
    0%{
        transform: rotate(deg);
    }
    25%{
        transform: rotate(15deg);
    }
    50%{
        transform: rotate(0deg);
    }
    75%{
        transform: rotate(-15deg);
    }
    100%{
        transform: rotate(0deg);
    }
}

@keyframes tilt{
    0%{
        transform: rotate(deg);
    }
    50%{
        transform: rotate(-30deg);
    }
    100%{
        transform: rotate(0deg);
    }
}

@keyframes fadein{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.menu{
    .hide{
        width: 0;
        opacity: 0;
        height: 0;
        transition: 0.3s ease-out;
        overflow: hidden;
    }
    .open{
        width: 100%;
        opacity: 1;
        transition: 0.2s;
    }
}

@media (min-width: $xs){
    .menu{
        .hide{
            height: 100%;
        }
    }
}

/**
 * Contact page specifc styles
 */

#contact-page{
    .heading{
        padding-top: 100px;
        margin-top: 275px;
        .push--right{
            margin: auto;
        }
    }
}

@media(min-width: $xs){
    #contact-page{
        .heading{
            margin-top: auto;
            padding: 24px 32px 24px 108px;
            .push--right{
                margin: 0 0 0 auto;
            }
            .block__heading__image{
                &--bottom{
                    img{
                        left: 170px;
                        //top: 21px;
                        bottom: 0;
                        top: auto;
                    }
                }
            }
        }
    }
}

@media(min-width: $sm){
    #contact-page{
        .heading{
            padding: 24px 32px 24px 108px;
            margin-top: auto;
            align-items: flex-end;
            .block__heading__image{
                &--bottom{
                    img{
                        padding: 0;
                        transform: none;
                        left: 75px;
                        top: auto;
                    }
                }
            }
        }
    }
}

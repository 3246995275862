.bg{
    &-orange{
        background: $orange;
    }
    &-darkorange{
        background: $dark-orange;
    }
    &-green{
        background: $green;
    }
    &-lightgreen{
        background: $light-green;
    }
    &-blue{
        background: $blue;
    }
    &-lightblue{
        background: $light-blue;
    }
    &-red{
        background: $red;
    }
    &-lightred{
        background: $light-red;
    }
    &-purple{
        background: $purple;
    }
    &-lightpurple{
        background: $light-purple;
    }
    &-darkgrey{
        background: $dark-grey;
    }
    &-grey1{
        background: $grey1;
    }
    &-grey2{
        background: $grey2;
    }
    &-grey3{
        background: $grey3;
    }
    &-white{
        background: $white;
    }
}

.color{
    &-orange{
        color: $orange;
    }
    &-darkorange{
        color: $dark-orange;
    }
    &-green{
        color: $green;
    }
    &-lightgreen{
        color: $light-green;
    }
    &-blue{
        color: $blue;
    }
    &-lightblue{
        color: $light-blue;
    }
    &-red{
        color: $red;
    }
    &-lightred{
        color: $light-red;
    }
    &-purple{
        color: $purple;
    }
    &-lightpurple{
        color: $light-purple;
    }
    &-darkgrey{
        color: $dark-grey;
    }
    &-grey1{
        color: $grey1;
    }
    &-grey2{
        color: $grey2;
    }
    &-grey3{
        color: $grey3;
    }
    &-white{
        color: $white;
    }
}

#breaker{
    //overflow-x: hidden;
    //overflow-y: show;
    .breaker{
        display: flex;
        height: 550px;
        position: relative;
        //overflow-y: hidden; //Breaks?
        &.parallax{
            //margin-right: -150px;
            //padding-right: 150px;
            overflow-x: hidden;
            overflow-y: visible;
            height: 650px;
        }
        &--parallax{
            backface-visibility: hidden;
            &--foreground{
                z-index: 10;
                will-change: transform;
                width: 100px;
                height: auto;
                //height: 56px;
                transform: translateX(0px);
            }
            &--midground{
                //z-index: -20;
                will-change: transform;
                width: 75px;
                height: auto;
                transform: translateX(0px);
            }
            &--background{
                //z-index: -30;
                will-change: transform;
                width: 50px;
                height: auto;
                transform: translateX(0px);
            }
        }
        &--pop{
            //transform: scale(1, 1);
            top: -125px;
            transition: 0.1s;
            will-change: transform;
            backface-visibility: hidden;
            &:hover{
                transform: scale(1.1, 1.1);
                transition: 0.1s;
            }
        }
        &--shake{
            will-change: transform;
            backface-visibility: hidden;
            &:hover{
                animation: shake 0.1s linear;
            }
        }
        &--tilt{
            transition: 0.1s;
            will-change: transform;
            backface-visibility: hidden;
            &:hover{
                transform: rotate(-18deg);
                transition: 0.1s;
            }
        }
        &__text{
            margin: auto;
            display: flex;
            //width: 100%;
            z-index: 10;
            position: relative;
            backface-visibility: hidden;
            // p{
            //     font-size: 2em; /* 32/16 */
            // }
            &--right{
                justify-content: center;
                left: 10%;
            }
            &--left{
                justify-content: center;
                right: 10%;
            }
        }
        &__item{
            &.parallax{
                img{
                    position: absolute;
                }
            }
            &.heads{
                img{
                    position: absolute;
                }
            }
        }
    }
}

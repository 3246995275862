//==================== Menu ====================//

.menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  backface-visibility: hidden;
  opacity: 0.87;
  z-index: 999;
  width: 100%;
  max-width: 1600px;
  left: 50%;
  transform: translateX(-50%);
  &__main{
    display: flex;
    cursor: pointer;
    padding: $large-gutter;
    flex-direction: row;
    align-items: center;
    background: #fff;
    height: 100%;
    .text{
      margin-right: 12px;
      height: 20px;
    }
    .arrow{
      transform: rotate(180deg);
      transition: 0.3s ease-out;
      height: 20px;
    }
  }
  &__options{
    //justify-content: center;
    display: flex;
    background: #fff;
    //position: fixed;
    align-items: center;
    height: 100%;
    transition: 0.3s ease-out;
    ul{
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      list-style: none;
      padding-left: 0;
      li{
        margin-left: 0;
        margin: 0 auto;
        margin-bottom: 8px;
        padding-top: $gutter;
        padding-bottom: $gutter;
        a{
          color: $dark-grey;
          text-decoration: none;
          font-size: 1em; /* 20/16 */
          font-weight: $fontLight;
        }
      }
    }
  }
}


@media (min-width: $xs){
  .menu{
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 52px;
    &__main{
      padding: $gutter;
      .text{
        margin-right: 12px;
      }
    }
    &__options{
      background: #fff;
      width: 100%;
      will-change: width;
      ul{
        display: flex;
        flex-direction: row;
        list-style: none;
        padding-left: 0;
        margin: 0 0 0 auto;
        li{
          margin: 0 27px 0 0;
          a{
            font-size: 1em;
          }
        }
      }
    }
  }
}

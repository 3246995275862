/**
 * Button Styles
 */

.button{
    padding: $gutter;
    &--orange{
        border: #fff 2px solid;
        border-radius: 5px;
        color: #fff;
    }
}

@media(min-width: $xs){
    .button{
        padding: $gutter;
        margin-bottom: 24px;
        &--orange{
            border: #fff 2px solid;
            border-radius: 5px;
            color: #fff;
        }
    }
}

//==================== Flex Classes ====================//

.flex {
  display: flex;
  align-items: center;
}

.column {
  flex-flow: column;
  justify-content: center;
}

.right {
  margin-left: auto;
}
.left {
  margin-right: auto;
}

/**
  *
  * Block component
  * .block
  *
  * Modifiers
  * .block--sm - Make height of block small
  * .block--med - Make height of block medium
  * .block--lg - Make height of block large
  *
  */
.block{
  flex: 1;
  margin: 4px;
  display: flex;
  position: relative;
  z-index: 10;
  backface-visibility: hidden;
  //background: $grey1;

  //-- Modifiers
  &--sm{
    height: 350px;
  }
  &--med{
    height: 350px;
  }
  &--lg{
    height: 350px;
    h2{
      z-index: 10;
      font-size: 10.25em; /* 100/16 */
      color: #fff;
      margin: auto;
      opacity: 0.87;
    }
  }
  &--auto{
    height: auto;
  }

  /**
   * The block on the main page that says "Hi"
   */
  .hi-block{
    position: relative;
    padding: 32px 42px;
    width: 100%;
    .image-area{
      position: absolute;
      top: 0;
      left: 0;
      padding: 32px 42px;
      transform: translateY(-50%);
      top: 65%;
      img{
        width: 100%;
        height: auto;
      }
    }
    .text-area{
      position: absolute;
      top: 0;
      left: 0;
      padding: 32px 42px;
      transform: translate(-50%, -50%);
      top: 35%;
      left: 50%;
      z-index: 10;
      h1{
        z-index: 10;
        font-size: 10.25em;
        color: #fff;
        margin: auto;
        opacity: 0.87;
      }
    }
  }

  /**
   * Blocks with social media stuff in them
   */
  .social-media-block{
    display: flex;
    flex-direction: column;
    color: #fff;
    margin: auto;
    a{
      text-decoration: none;
      &:visited,
      &:hover{
        text-decoration: none;
      }
    }
    .item{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 28px;
      div:first-child{
        width: 34px;
        align-items: center;
        justify-content: center;
        margin-right: 18px;
      }
    }
  }

  /**
    * Heading block
    * .block__heading
    *
    * Modifiers
    * .block__heading--green - Makes background green
    * .block__heading--orange - Makes background orange
    * .block__heading--blue - Makes background blue
    *
    * @TODO Create the specifications for the image overflowing out of the block
    *
    */
  &__heading{
    padding: 150px 32px 48px 32px;
    margin-top: 350px;
    height: auto;
    align-items: center;
    position: relative;
    &.robot-block{
      margin-top: 170px;
    }
    p{
      color: #fff;
      font-size: 24px;
    }
    &__text{
      p{
        font-size: 1.5em;
      }
    }
    &__image{
      &--right{
        img{
          position: absolute;
          right: 48px;
          top: -169px;
          transform: translateX(-50%);
          left: 50%;
        }
      }
      &--left{
        img{
          position: absolute;
          left: 32px;
          top: -172px;
          //transform: rotate(18deg);
          transform: translateX(-50%);
          left: 50%;
        }
      }
      &--bottom{
        img{
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -75%;
          padding-left: 5%;
          padding-right: 5%;
        }
      }
      &--left,
      &--right{
        img{
          max-width: 350px;
          height: auto;
        }
      }
    }

    //-- Modifiers
    &--green{
      background: $grad-right-darkGreen-green;
    }
    &--orange{
      background: $grad-right-orange-darkOrange;
    }
    &--blue{
      background: $grad-right-blue-purple;
    }
  }

  /**
  * Image block
  * .block__img
  *
  */
  &__img{
    position: relative;
    overflow: hidden;
    cursor: default;
    img{
      top: 50%;
      //left: 50%;
      width: 100%;
      //height: 100%;
      position: absolute;
      transform: translateX(-50%);
      transform: translateY(-50%);
    }
  }

  /**
  * Text Block
  * .block__text
  */
  &__text{
    padding: 34px 42px;
    flex-direction: column;
    width: 100%;
    h3{
      font-size: 2em; /* 32/16 */
      color: white;
    }
    p{
      color: white;
      //display: none;
      //visibility: hidden;
    }
  }

  &__text-2{
    padding: 20px 26px;
  }

  /**
   * Text-image block
   *
   * .block__text-image
   *
   */

  &__text-image{
    position: relative;
    overflow: hidden;
    padding: 34px 42px;
    .text-area{
      z-index: 20;
    }
    .image-area{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      img{
        width: 100%;
        height: auto;
      }
    }
  }

  /**
   * Social media block
   *
   * .block__social-media
   */
  &__social-media{
    padding: 28px;
    display: flex;
    width: 100%;
    flex-direction: column;
    &--row{
      display: flex;
      flex: 1;
      .item{
        display: flex;
        //height: 100%;
        flex: 1;
        align-items: center;
        justify-content: center;
        i{
          color: #fff;
          font-size: 2.25em; /* 36/16 */
        }
      }
      &:nth-child(1){
        .item{
          &:nth-child(1){
            border-right: 1px #fff solid;
          }
          &:nth-child(2){
            //border-bottom: 1px #fff solid;
          }
        }
      }
      &:nth-child(2){
        .item{
          &:nth-child(1){
            border-top: 1px #fff solid;
          }
          &:nth-child(2){
            border-left: 1px #fff solid;
            border-top: 1px #fff solid;
          }
        }
      }
    }
  }
  iframe{
    //display: flex;
    width: 100%;
    height: 100%;
    //flex: 1;
  }
}

.hat-plant{

  .hat{
    top: -325px;
    z-index: 10;
    height: auto;
    transition: 0.1s;
    will-change: transform;
    p{
      visibility: hidden;
    }
    &:hover{
      //transform-origin: center center;
      transform: rotate(-15deg);
      transition: 0.1s;
      p{
        visibility: visible;
      }
    }
  }
  .plant{
      top: -325px;
  }
}

@media (min-width: $xs){
  .hat-plant{
    .hat{
      top: -168px;
    }
    .plant{
        top: -167px;
    }
  }
}

/**
 * Tablet
 */
@media (min-width: $xs){
  .block{
    //-- Modifiers
    &--sm{
      height: 350px;
    }
    &--med{
      height: 340px;
    }
    &--lg{
      height: 700px;
      h2{
        font-size: 12.5em; /* 200/16 */
      }
    }
    &--xl-text{
      p{
        font-size: 42px;
      }
    }

    /**
    * Heading block
    * .block__heading
    *
    * Modifiers
    * .block__heading--green - Makes background green
    * .block__heading--orange - Makes background orange
    * .block__heading--blue - Makes background blue
    *
    * @TODO Create the specifications for the image overflowing out of the block
    *
    */
    &__heading{
      padding: 24px 32px 24px 108px;
      margin-top: 0px;
      height: 274px;
      align-items: center;
      position: relative;
      &.robot-block{
        margin-top: 0;
      }
      p{
        color: #fff;
        font-size: 24px;
      }
      &__text{
        &--long{
          max-width: 75%;
        }
        &--med{
          max-width: 45%;
        }
        p{
          font-size: 24px;
        }
      }

      &__image{
        &--right{
          img{
            position: absolute;
            right: 48px;
            top: -169px;
            transform: none;
            left: auto;
          }
        }
        &--left{
            img{
              position: absolute;
              left: 32px;
              top: -172px;
              //transform: rotate(18deg);
              transform: none;
              right: auto;
          }
        }
        &--bottom{
          img{
            position: absolute;
            left: -42px;
            top: -79px;
          }
        }
        &--left,
        &--right{
          img{
            max-width: 408px;
            height: auto;
          }
        }
      }

      //-- Modifiers
      &--green{
        background: $grad-right-darkGreen-green;
      }
      &--orange{
        background: $grad-right-orange-darkOrange;
      }
      &--blue{
        background: $grad-right-blue-purple;
      }
    }
  }
}

/**
 * Desktop
 */
@media(min-width: $sm){
  .block{
    //-- Modifiers
    &--sm{
      height: 250px;
    }
    &--med{
      height: 340px;
    }
    &--lg{
      height: 500px;
      h2{
        font-size: 12.5em; /* 200/16 */
      }
    }
    &--xl-text{
      p{
        font-size: 42px;
      }
    }

    .social-media-block{
      margin: 0;
    }

    /**
    * Heading block
    * .block__heading
    *
    * Modifiers
    * .block__heading--green - Makes background green
    * .block__heading--orange - Makes background orange
    * .block__heading--blue - Makes background blue
    *
    * @TODO Create the specifications for the image overflowing out of the block
    *
    */
    &__heading{
      padding: 24px 32px 24px 108px;
      height: 274px;
      align-items: center;
      position: relative;
      p{
        color: #fff;
        font-size: 24px;
      }
      &__text{
        &--long{
          max-width: 75%;
        }
        &--med{
          max-width: 60%;
        }
        p{
          font-size: 32px;
        }
      }

      &__image{
        &--right{
          img{
            position: absolute;
            right: 48px;
            top: -169px;
          }
        }
        &--left{
          img{
            position: absolute;
            left: -42px;
            top: -82px;
            //transform: rotate(18deg);
          }
        }
        &--left,
        &--right{
          img{
            max-width: 408px;
            height: auto;
          }
        }
        &--bottom{
          img{
            position: absolute;
            //left: -42px;
            //top: -82px;
            left: 64px;
            bottom: 0;
          }
        }
      }

      //-- Modifiers
      &--green{
        background: $grad-right-darkGreen-green;
      }
      &--orange{
        background: $grad-right-orange-darkOrange;
      }
      &--blue{
        background: $grad-right-blue-purple;
      }
    }
    &__profile{
      cursor: pointer;
      .name-tag{
        will-change: opacity, bottom;
        transform: translateY(28%);
        opacity: 0;
        overflow: hidden;
        transition: 0.3s ease-in-out;
      }
      &:hover{
        .name-tag{
          transform: translateY(0%);
          opacity: 0.82;
          transition: 0.3s ease-in-out;
        }
      }
    }
  }
}
